<template>
  <v-container class="pa-0 text-center fill-height" fluid>
    <v-row
      class="white fill-height"
      align-content="center"
      justify="center"
      no-gutters
    >
      <v-col cols="12" >
        <v-img
          alt="Success"
          contain
          height="175"
          src="../assets/success_check.gif"
        />
      </v-col>
         <v-col cols="12" >
        <p class="font-weight-bold" style="color: #3d3f3c">Opening door</p>
      </v-col>
        <v-col cols="12" >
        <p class="color-black caption px-2" style="color: #3d3f3c">Please enter within 10 secs after which the door would get locked again</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import APIHelper from "../apiHelper";
import { EventBus } from "@/lib/EventBus";
// import loggerHelper from "../loggerHelper";

export default {
  name: "SuccessPedestrianAccess",
  data: () => ({}),
  computed: {
    ...mapGetters({
      bookingId: "getterBookingId",
      bookingDetails: "getterBookingDetails",
    }),
  },

  mounted() {
    setTimeout(async () => {
      await this.getBookingState();
    }, 4000);
  },
  unmounted(){
    
  },
  methods: {
    async getBookingState() {
      if (this.bookingId != null) {
        try {
          var bDetails = await APIHelper(
            "GET",
            "/api/v1/booking/config/" + this.bookingId
          );
          let bookingState = bDetails.data?.data?.booking?.state
            ? bDetails.data.data.booking.state
            : null;
            this.$store.commit(
          "SET_BOOKING_DETAILS",
          bDetails.data?.data ? bDetails.data.data : null
        );
          if (bookingState == 8 || bookingState == 10) {
            this.postToLoggerAPI({
                phone: bDetails.data.data.user.contact,
                refId: this.bookingId,
                statusText: "Loaded pedestrian access success page.",
              })
            await this.$router.replace({ path: "/checkout" });
            if(this.bookingDetails?.booking?.autoP2PDetails)
             {
              EventBus.$emit('AUTO_P2P_BID', this.bookingDetails)
             }
            EventBus.$emit('SET_FREE_BOOKING')
          } else if (bookingState == 2) {
          await  this.$router.replace({ path: "/checkedin" });
          EventBus.$emit('GET_FREE_BOOKING')
          } else {
            setTimeout(async () => {
              await this.getBookingState();
            }, 4000);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>
<style lang="less">
</style>
